/*
|----------------------------------------------
| 	      Qu'est ce que la cybersécurité ?
|----------------------------------------------
|
*/

#partie-2 {
  background-color: #eff1f7;
  padding: 100px 0;
  h2 {
    text-align: left;
    color: #ec615f;
    font-size: 40px;
    font-weight: 300;
    line-height: 58px;
    padding-bottom: 10px;
    @media (max-width: 768px) {
      font-size: 24px;
      line-height: inherit;
    }
  }
}

#partie-3 {
  .section-p3 {
    margin: 60px 0;
    font-family: "Gotham Book";
    font-size: 16px;
    font-weight: 300;
    line-height: 32px;
    h2 {
      color: #ec615f;
      font-family: "Gotham Book";
      font-size: 40px;
      line-height: 58px;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 6.67px;
      text-align: left;
      padding: 60px 0 0 0;
      @media (max-width: 768px) {
        font-size: 22px;
        line-height: inherit;
      }
    }
    h3 {
      color: #404143;
      font-family: "Gotham Bold";
      font-size: 24px;
      font-weight: 700;
      line-height: 35px;
      padding-bottom: 10px;
      @media (max-width: 768px) {
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
      }
    }
    ul {
      li {
        font-family: "Gotham Book";
        font-size: 16px;
        font-weight: 300;
        padding: 10px 0;
        text-align: justify;
        @media (max-width: 480px) {
          text-align: inherit;
        }
        &:before {
          content: '';
          position: absolute;
          background-image: url(../img/arrow.svg);
          background-repeat: no-repeat;
          background-position: center;
          height: 22px;
          width: 8px;
          margin-left: -14px;
          display: block;
        }
      }
    }
  }
}

#accordion {
  background-color: #eff1f7;
  padding: 100px 0;
  margin: 100px auto 0px auto;
  h2 {
    span {
      color: #404143;
      font-family: "Gotham Bold";
      font-size: 30px;
      font-weight: 700;
      line-height: 38px;
      text-transform: none;
      letter-spacing: -0.22px;
      display: inline-block;
      margin-bottom: 15px;
      &:before {
        content: '';
        position: absolute;
        background-image: url(../img/arrow.svg);
        background-repeat: no-repeat;
        background-position: center;
        height: 34px;
        width: 8px;
        margin-left: -24px;
        display: block;
        margin-top: 2px;
      }
    }

    @media (max-width: 768px) {
      font-size: 24px;
      line-height: inherit;
    }
  }
  .card {
    margin-bottom: 20px;
    border: 2px solid #e4e8f2;

    .card-header {
      border: none;
      border-radius: 0;
      background-color: #eff1f7;
      padding: 0px;
      h3 {
        color: #404143;
        font-family: "Gotham Medium";
        font-size: 24px;
        font-weight: 500;
        padding: 18px 20px 20px 20px;
        &:after {
          content: '';
          position: absolute;
          background-image: url(../img/arrow.svg);
          background-repeat: no-repeat;
          height: 13px;
          width: 8px;
          margin-left: -24px;
          display: block;
          margin-top: 12px;
          transform: rotate(-90deg);
          transition:all ease 0.2s;
          position: absolute;
          right: 20px;
          top: 15px;
        }
      }
      a.collapsed {
        h3 {
          &:after {
            content: '';
            transform: rotate(90deg);
            transition:all ease 0.2s;
          }
        }
      }
    }
    .card-body {
      background-color: #eff1f7;
      text-align:justify;
      @media (max-width: 480px) {
        text-align: inherit;
      }
    }
  }
}

.page-template-page-cyber{
  #section-4{
    margin:100px auto;
  }
}