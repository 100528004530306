/*
|----------------------------------------------
| 	      L'assurance cybersécurité
|----------------------------------------------
|
*/

#partie-a-propos-1{
  opacity: 0.8;
  color: #404143;
  font-family: "Gotham Book";
  font-size: 20px;
  font-weight: 300;
  line-height: 26px;
}
#partie-a-propos-2{
  display: block;
  background-color: #eff1f7;
  margin: 90px auto 150px auto;
  padding-top: 100px;
  padding-bottom: 73px;
  .item {
    margin:auto;
    .bloc-item {
      text-align: center;
      margin-bottom: 30px;
      img {
        padding-bottom: 20px;
        display: block;
        margin: auto;
        height: 100px;
      }
      h3 {
        color: #363636;
        font-family: "Gotham Bold";
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        margin-top: 20px;
      }
    }
  }
  .bt-red{
    position: absolute;
    bottom: -100px;
    margin: auto;
    display: block;
    left: 0;
    right: 0;
    width: fit-content;
  }
}
