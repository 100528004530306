/*
|----------------------------------------------
| 	      L'assurance cybersécurité
|----------------------------------------------
|
*/

#partie-assurance-1 {
  margin: -120px auto 100px auto;
  .item {
    border-radius: 4px;
    border: 2px solid #ececec;
    background-color: #ffffff;
    padding: 75px 35px;
    z-index: 0;
    -webkit-transition: -webkit-transform .5s ease;
    transition: -webkit-transform .5s ease;
    transition: transform .5s ease;
    @media (max-width: 992px) {
      margin-bottom: 20px;
      opacity: 1;
    }
    h3 {
      color: #ec615f;
      font-family: "Gotham Book";
      font-size: 22px;
      font-weight: 300;
      text-transform: uppercase;
      padding-bottom: 13px;
      //opacity: 0.4;
      @media (max-width: 992px) {
        opacity: 1;
      }
    }
    .body-item {
      color: #404143;
      font-family: "Gotham Book";
      font-size: 16px;
      font-weight: 300;
      line-height: 25px;
      //opacity: 0.4;
      @media (max-width: 992px) {
        opacity: 1;
      }
    }
    &.active,
    &:hover {
      opacity: 1;
      -webkit-transform: scale(1.10);
      transform: scale(1.10);
      box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.20);
      z-index: 1;
      @media (max-width: 992px) {
        -webkit-transform: scale(1);
        transform: scale(1);
        box-shadow: none;
      }
      h3 {
        opacity: 1;
      }
      .body-item {
        opacity: 1;
      }
    }
  }
}

#partie-assurance-2 {
  opacity: 0.9;
  color: #404143;
  font-family: "Gotham Book";
  font-size: 20px;
  font-weight: 300;
  line-height: 32px;
  text-align: justify;
  @media (max-width: 480px) {
    text-align: inherit;
  }
}

#partie-assurance-3 {
  .titre_assurance_3 {
    color: #404143;
    font-family: "Gotham Book";
    font-size: 40px;
    font-weight: 300;
    line-height: 58px;
    text-transform: uppercase;
    letter-spacing: 6.67px;
    text-align: center;
  }
  .sstitre_assurance_3 {
    color: #404143;
    font-family: "Gotham Book";
    font-size: 20px;
    font-weight: 300;
    line-height: 58px;
    text-transform: uppercase;
    text-align: center;
    padding-bottom: 70px;
  }
  .item {
    height: 50px;
    display: table;
    margin-bottom: 40px;
    .bloc-item {
      h3 {
        color: #ec615f;
        font-family: "Gotham Bold";
        font-size: 17px;
        font-weight: 700;
        line-height: 24px;
        height: 50px;
        width: 100%;
        display: table-cell;
        /* vertical-align: middle; */
        padding: 7px 0 0 0;
      }
      img {
        float: left;
        padding-right: 10px;
        max-width: 50px;
      }
    }
  }
}

#partie-assurance-4 {
  background-color: #eff1f7;
  padding: 100px 0;
  h2 {
    text-align: left;
    color: #ec615f;
    font-size: 40px;
    font-weight: 300;
    line-height: 58px;
    padding-bottom: 10px;

    @media (max-width: 768px) {
      font-size: 24px;
      line-height: inherit;
    }
  }
}

#partie-assurance-5 {
  margin: 100px auto 200px auto;
  .slider {
    margin-top: 60px;
    .block-item {
      width: 100%;
      .item {
        background-color: #ffffff;
        width: 100%;
        text-align: left;
        padding: 15px;
        @media (max-width: 992px) {
          text-align: center;
        }
        .img-item {
          height: 80px;
          img {
            max-width: 54px;
            margin: 0 auto 20px 0;
            margin-bottom: 20px;
          }
        }
        h3 {
          color: #ec615f;
          font-family: "Gotham Bold";
          font-size: 20px;
          font-weight: 700;
          line-height: 30px;
          padding: 20px 0 10px 0;
        }
        .texte {
          opacity: 0.9;
          color: #404143;
          font-family: "Gotham Book";
          font-size: 16px;
          font-weight: 300;
          line-height: 30px;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .marg170 {
    margin-left: 170px;
  }
}