/*
|-----------------------
| 	     HEADER
|-----------------------
|
*/
@media (min-width: 992px) {
  .navbar-expand-lg {
    padding: 15px 1rem;
    background-color: #fff;
    .navbar-nav {
      margin: auto;
      li {
        border-radius: 4px;
        border: 2px solid #fff;
        margin: 0;
        a {
          color: #404143;
          font-family: "Gotham Book";
          font-size: 16px;
          font-weight: 300;
          line-height: 19px;
          width: 100%;
          display: block;
          padding: 15px 15px 15px 15px;
          @media (max-width: 1420px) {
            font-size: 14px;
          }
        }
        &.menu-subcribe {
          border-radius: 4px;
          border: 2px solid #ec615f;
          background-color: #ec615f;
          padding: 0;
          margin: 0 15px;
          transition: all ease 0.3s;
          &:hover {
            transition: all ease 0.3s;
            background-color: #fff;
            a {
              color: #ec615f;
            }
          }
          a {
            color: #fff;
            padding: 15px 30px 15px 30px;
            font-family: "Gotham Medium";
            font-weight: 500;
          }
        }
        &.menu-contact {
          border-radius: 4px;
          border: 2px solid #ec615f;
          padding: 0;
          margin: 0 15px;
          transition: all ease 0.3s;
          &:hover {
            transition: all ease 0.3s;
            border: 2px solid #ec615f;
            background-color: #ec615f;
            a {
              color: #fff;
            }
          }
          a {
            color: #404143;
            padding: 15px 30px 3px 30px;
          }
        }
        &.menu-phone {
          a {
            color: #ec615f;
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  img {
    width: 160px;
  }
}

@media (max-width: 992px) {
  .navbar-brand {
    margin: auto;
    height: 94px;
    padding-top: 20px;
    img {
      margin: auto;
      position: absolute;
      padding-top: 22px;
      width: 160px;
      left: 0;
      right: 0;
      top: 0;
      display: block;
    }
  }
}

@media (max-width: 768px) {
  .navbar-brand {
    img {
      padding-top: 30px;
      max-width: 100px;
    }
  }
}

.header-bg {
  background-size: cover;
  background-position: center;
  height: 800px;
  .content-title {
    display: table;
    height: 800px;
    width: 100%;
    text-align: center;
    max-width: 920px;
    margin: auto;
    padding: 0 15px;
    .block-title {
      display: table-cell;
      height: 800px;
      vertical-align: middle;
      h1 {
        color: #ffffff;
        font-family: "Gotham Medium";
        font-size: 48px;
        font-weight: 500;
        line-height: 70px;
        text-transform: uppercase;
        @media (max-width: 768px) {
          font-size: 24px;
          line-height: 30px;
        }
      }
    }
  }
}

.page-template-default,
.page-template-page-a-propos,
.page-template-page-cyber,
.page-template-page-assurance {
  .header-bg {
    .content-title {
      text-align: left;
      max-width: inherit;
      h1 {
        font-family: "Gotham Book";
        @media (max-width: 480px) {
          text-align: center;
          padding-bottom: 10px;
        }
      }
      .intro {
        opacity: 0.8;
        color: #ffffff;
        font-family: "Gotham Medium";
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;
        @media (max-width: 480px) {
          font-size: 16px;
          font-weight: 500;
          line-height: 28px;
          text-align: center;
        }
      }
    }
  }
}

section {
  margin: 100px auto;
}

@media (max-width: 992px) {
  .navbar-nav {
    li {
      background-color: #fbfbfb;
      transition: all ease 0.3s;
      z-index: 10;
      &:hover {
        background-color: #efefef;
        transition: all ease 0.3s;
      }
      a {
        display: block;
        padding: 20px 20px 20px 20px;
      }
    }
  }
  .navbar {
    padding: 0;
  }
  .navbar-toggler {
    margin-left: 20px;
  }
}

.logo-snaac {
  width: 180px;
}