/*
|-----------------------
| 	    DEFAULTS
|-----------------------
|
*/

body {
  font-family: 'arial';
  color: $default-color;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

a {
  color: red;
  font-family: "Gotham Book";
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;

  &:hover {
    color: black;
  }
}

.clearfix {
  clear: both;
}

p {
  opacity: 0.9;
  color: #404143;
  font-family: "Gotham Book";
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
}

#content {
  ul {
    margin: 20px 0;
    li {
      position: relative;
      padding-right: 50px;
      text-align: left;
      display: inline-block;
      color: #404143;
      font-family: "Gotham Book";
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      padding-bottom: 14px;
      @media (max-width: 768px) {
        padding-right: 0px;
        text-align: left;
        display: block;
      }
      &:before {
        content: '';
        position: absolute;
        background-image: url(../img/arrow.svg);
        background-repeat: no-repeat;
        background-position: center;
        height: 22px;
        width: 8px;
        margin-left: -14px;
        display: block;
      }
      &:last-child {
        padding-right: 0px;
      }
    }
  }
}

strong {
  font-weight: bold;
}

.txt-c {
  text-align: center;
}

.bt {
  color: #ffffff;
  font-family: "Gotham Medium";
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
  text-align: center;
  display: inline-block;
  margin: 50px auto 0 auto;
  padding: 15px 30px 14px 30px;
}

.wpcf7-submit,
.bt-red {
  background-color: #ec615f;
  border: 1px solid #ec615f;
  color: #ffffff;
  transition: all ease 0.4s;
  &:hover {
    background-color: #fff;
    border: 1px solid #ec615f;
    color: #ec615f;
    transition: all ease 0.4s;
  }
}

h2 {
  color: #404143;
  font-family: "Gotham Book";
  text-align: center;
  font-size: 34px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 5.83px;
  padding-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 24px;
  }
}

.ss-titre {
  text-align: center;
}


.page-template-default {
  .header-bg {
    height: 400px;
    .content-title {
      height: 400px;
      .block-title {
        height: 400px;
      }
    }
  }
}

.desk-show{
display: block;
  @media (max-width: 990px) {
    display: none;
  }
}
.mob-show{
  display: none;
  @media (max-width: 990px) {
    display: block;
  }
}