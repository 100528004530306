/*
|-----------------------
| 	     FONTS
|-----------------------
|
*/

// 100    Extra Light or Ultra Light
// 200    Light or Thin
// 300    Book or Demi
// 400    Normal or Regular
// 500    Medium
// 600    Semibold, Demibold
// 700    Bold
// 800    Black, Extra Bold or Heavy
// 900    Extra Black, Fat, Poster or Ultra Black

@font-face {
  font-family: 'Gotham Medium';
  src: url('../../fonts/Gotham-Medium.eot');
  src: url('../../fonts/Gotham-Medium.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Gotham-Medium.woff2') format('woff2'),
  url('../../fonts/Gotham-Medium.woff') format('woff'),
  url('../../fonts/Gotham-Medium.ttf') format('truetype'),
  url('../../fonts/Gotham-Medium.svg#Gotham-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Bold';
  src: url('../../fonts/Gotham-Bold.eot');
  src: url('../../fonts/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Gotham-Bold.woff2') format('woff2'),
  url('../../fonts/Gotham-Bold.woff') format('woff'),
  url('../../fonts/Gotham-Bold.ttf') format('truetype'),
  url('../../fonts/Gotham-Bold.svg#Gotham-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Book';
  src: url('../../fonts/Gotham-Book.eot');
  src: url('../../fonts/Gotham-Book.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Gotham-Book.woff2') format('woff2'),
  url('../../fonts/Gotham-Book.woff') format('woff'),
  url('../../fonts/Gotham-Book.ttf') format('truetype'),
  url('../../fonts/Gotham-Book.svg#Gotham-Book') format('svg');
  font-weight: normal;
  font-style: normal;
}