/*
|-----------------------
| 	    CONTENT
|-----------------------
|
*/

.article-body{
  margin:40px auto;
}

.texte-content{
  opacity: 0.9;
  color: #404143;
  font-family: "Gotham Book";
  font-size: 16px;
  font-weight: 300;
  line-height: 32px;
  text-align: justify;
  @media (max-width: 480px) {
    text-align: inherit;
  }
}

.cms{
    ul{
        li {
            display: block !important;
        }
    }

}