/*
|----------------------------------------------
| 	      L'assurance cybersécurité
|----------------------------------------------
|
*/

.page-template-page-contact {
  background-color: #eff1f7;
  #partie-contact-1 {
    .block-form {
      box-shadow: 0 24px 32px rgba(0, 0, 0, 0.15);
      background-color: #ffffff;
      h2 {
        text-align: left;
        padding: 45px 0 20px 80px;
        @media (max-width: 1200px) {
          font-size: 17px;
        }
        @media (max-width: 768px) {
          padding: 55px 15px 35px 15px;
          line-height: 35px;
        }
      }
      .formulaire {
        padding: 0 80px ;
        @media (max-width: 768px) {
          padding: 0 15px 20px 15px;
        }
        #wpcf7-f5-o1 {
          input {
            border-radius: 4px;
            border: 2px solid #ec615f;
            height: 53px;
            margin-bottom: 20px;
            padding: 0 20px;
            width: 100%;
            color: #404143;
            font-size: 16px;
            font-weight: 300;
          }
          textarea {
            border-radius: 4px;
            border: 2px solid #ec615f;
            height: 206px;
            margin-bottom: 20px;
            padding: 20px;
            width: 100%;
            color: #404143;
            font-size: 16px;
            font-weight: 300;
          }
          .wpcf7-submit {
            color: #ffffff;
            font-family: "Gotham Medium";
            font-size: 16px;
            font-weight: 500;
            line-height: 16px;
            padding: 10px 40px 10px 40px;
            width: inherit;
            height: 45px;
            &:hover {
              color: #ec615f;
              cursor: pointer;
            }
          }
          .wpcf7-response-output{
            margin: 0 0 20px 0;
            border-width: 1px;
          }
        }
      }
    }
    .bg-grey{
      background-color: #eff1f7;
    }
    .map {
      width: 100%;
      background-color: #efefef;
      height: 578px;
    }
    .zone-grey {
      background-color: #eff1f7;
      div {
        padding: 20px;
        strong{
          &:before {
            content: '';
            position: absolute;
            background-image: url(../img/arrow.svg);
            background-repeat: no-repeat;
            background-position: center;
            height: 13px;
            width: 8px;
            margin-left: -14px;
            margin-top: 3px;
            display: block;
          }
        }
      }
    }
  }
}