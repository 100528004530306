/*
|-----------------------
| 	      HOME
|-----------------------
|
*/

.home {
  .header-bg {
    height: 640px;
    @media (max-width: 768px) {
      height: 400px;
    }
    .content-title {
      height: 640px;
      @media (max-width: 768px) {
        height: 400px;
      }
      .block-title {
        display: table-cell;
        height: 640px;
        vertical-align: middle;
        @media (max-width: 768px) {
          height: 400px;
        }
        h1 {
          display: table-cell;
          height: 640px;
          vertical-align: middle;
          padding-bottom: 90px;
          @media (max-width: 768px) {
            height: 400px;
          }
        }
      }
    }
  }
}

#section-1 {
  .simulateur {
    background-color: #fff;
    margin-top: -240px;
    padding: 80px 0 0 0;
    border-radius: 4px;
    @media (max-width: 768px) {
      margin-top: -130px;
      padding: 30px 0 0 0;
    }
    .formfield-select {
      position: relative;
    }
    .formfield-select--container {
      position: relative;
      background-color: #fff;
      border: none;
      border-bottom: 2px solid #000;
      margin: 0 0 1.5em 0;
      overflow: hidden;
    }
    .formfield-select--container select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-color: #fff;
      width: 110%;
      height: auto;
      border: 0;
      margin: 0;
      border-radius: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #404143;
      font-family: "Gotham Book";
      font-size: 20px;
      font-weight: 300;
      outline: none;
      @media (max-width: 1200px) {
        font-size: 18px;
      }
      @media (max-width: 992px) {
        font-size: 15px;
      }
    }
    .formfield-select--container::after {
      content: '';
      position: absolute;
      top: 50%;
      margin-top: -9px;
      right: .75em;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-top-color: #444;
      border-width: 6px;
      border-style: solid;
      pointer-events: none;
      @media (max-width: 992px) {
        margin-top: -6px;
      }
    }

    h2 {
      color: #ec615f;
      font-family: "Gotham Bold";
      font-size: 30px;
      font-weight: 700;
      text-transform: none;
      letter-spacing: inherit;
      padding-bottom: 40px;
    }
    .txt1 {
      opacity: 0.5;
      color: #404143;
      font-family: "Gotham Book";
      font-size: 16px;
      font-weight: 300;
      line-height: 19px;
      text-align: center;
      text-transform: uppercase;
      padding: 60px 0 30px 0;
    }
    .txt2 {
      color: #404143;
      font-family: "Gotham Bold";
      font-size: 40px;
      font-weight: 700;
      line-height: 40px;
      text-transform: uppercase;
      text-align: center;
      @media (max-width: 992px) {
        font-size: 30px;
        line-height: 30px;
      }
    }
    .txt3 {
      display: block;
      text-align: center;
      margin: 40px auto 0 auto;
      @media (max-width: 768px) {
        display: table;
      }
      li {
        position: relative;
        padding-right: 50px;
        text-align: center;
        display: inline-block;
        color: #404143;
        font-family: "Gotham Book";
        font-size: 16px;
        font-weight: 300;
        @media (max-width: 768px) {
          padding-right: 0px;
          text-align: left;
          display: block;
        }
        &:before {
          content: '';
          position: absolute;
          background-image: url(../img/arrow.svg);
          background-repeat: no-repeat;
          background-position: center;
          height: 22px;
          width: 8px;
          margin-left: -14px;
          display: block;
        }
        &:last-child {
          padding-right: 0px;
        }
      }
    }
    .bt-sub {
      margin: 50px auto 0 auto;
      display: table;
      text-align: center;
      cursor: pointer;
    }
  } 
}

#section-2 {
  height: 420px;
  .item {
    position:relative;
    .image_video {
      background-position: center;
      background-repeat: no-repeat;
      width: 750px;
      height: 420px;
      margin: auto;
      display: block;
      cursor: pointer;
      opacity: 1;
      transition: all ease 0.3s;
      box-shadow: 0 5px 25px #b9b9b9;
      z-index: 1;
      position: absolute;
      left: 0;
      right: 0;
      @media (max-width: 992px) {
        width: 100%;
      }
      &:before {
        content: '';
        background-image: url(../img/play.png);
        height: 80px;
        width: 80px;
        display: block;
        position: absolute;
        top: 170px;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 2;
        transition: all ease 0.3s;
      }
      &:hover {
        transition: all ease 0.3s;
        box-shadow: 0 5px 10px #b9b9b9;
        &:before {
          content: '';
          background-image: url(../img/play-h.png);
          transition: all ease 0.3s;
        }
      }
    }
    .video_yt {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      text-align: center;
    }
  }
}

#basicModal {
  .modal-dialog {
    max-width: min-content;
  }
}

#section-3 {
  .hexagone {
    margin-top: 60px;
    .block-item {
      width: 100%;
      .item {
        background-image: url("../img/hexagon.png");
        text-align: center;
        padding: 15px;
        width: 306px;
        height: 279px;
        margin: auto;
        display: block;
        @media (max-width: 768px) {
          height: inherit;
          background: none;
        }
        h3 {
          color: #ec615f;
          font-family: "Gotham Medium";
          font-size: 20px;
          font-weight: 500;
          line-height: 27px;
          text-transform: uppercase;
          padding: 50px 50px 0 50px;
          @media (max-width: 768px) {
            padding: 10px 10px 0 10px;
          }
        }
        .texte {
          opacity: 0.9;
          color: #404143;
          font-family: "Gotham Book";
          font-size: 16px;
          font-weight: 300;
          line-height: 24px;
          padding: 6px 35px 0 35px;
        }
      }
    }

    .item-0 {
      width: 50%;
      @media (max-width: 992px) {
        width: 50%;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
      .item {
        float: right;
        margin-right: 55px;
        @media (max-width: 992px) {
          float: inherit;
          margin: auto;
        }
      }
    }
    .item-1 {
      width: 50%;
      @media (max-width: 992px) {
        width: 50%;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
      .item {
        float: left;
        margin-left: 55px;
        @media (max-width: 992px) {
          float: inherit;
          margin: auto;
        }
      }
    }

    .item-2 {
      width: 33.33%;
      margin-top: -150px;
      @media (max-width: 1200px) {
        margin-top: 0px;
      }
      @media (max-width: 992px) {
        width: 50%;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
      .item {
        float: left;
        @media (max-width: 992px) {
          float: inherit;
          margin: auto;
        }
      }
    }
    .item-3 {
      width: 33.33%;
      margin-top: -150px;
      @media (max-width: 1200px) {
        margin-top: 0px;
      }
      @media (max-width: 992px) {
        width: 50%;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    .item-4 {
      width: 33.33%;
      margin-top: -150px;
      @media (max-width: 1200px) {
        margin-top: 0px;
      }
      @media (max-width: 992px) {
        width: 50%;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
      .item {
        float: right;
        @media (max-width: 992px) {
          float: inherit;
          margin: auto;
        }
      }
    }

    .item-5 {
      width: 50%;
      @media (max-width: 992px) {
        width: 50%;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
      .item {
        float: right;
        margin-right: 55px;
        margin-top: -150px;
        @media (max-width: 1200px) {
          margin-top: 0px;
        }
        @media (max-width: 992px) {
          float: inherit;
          margin: auto;
        }
      }
    }
    .item-6 {
      width: 50%;
      @media (max-width: 992px) {
        width: 50%;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
      .item {
        float: left;
        margin-left: 55px;
        margin-top: -150px;
        @media (max-width: 1200px) {
          margin-top: 0px;
        }
        @media (max-width: 992px) {
          float: inherit;
          margin: auto;
        }
      }
    }
  }
}

#section-4 {
  margin: 100px auto 130px auto;
  @media (max-width: 992px) {
    margin: 100px auto 60px auto;
  }
  .slider {
    margin-top: 60px;
    .block-item {
      width: 100%;
      .item {
        background-color: #ffffff;
        width: 100%;
        text-align: center;
        padding: 15px;
        h3 {
          color: #ec615f;
          font-family: "Gotham Bold";
          font-size: 40px;
          font-weight: 700;
          line-height: 30px;
          padding-bottom: 20px;
        }
        .texte {
          opacity: 0.9;
          color: #404143;
          font-family: "Gotham Book";
          font-size: 16px;
          font-weight: 300;
          line-height: 24px;
          padding: 0 40px;
        }
      }
    }
  }
}

#section-5 {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    height: 500px;
    width: 100%;
    top: -60px;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #e4e8f2;
    opacity: 0.5;
    @media (max-width: 992px) {
      display: none;
    }
  }
  h2 {
    color: #ec615f;
  }
  .slider {
    margin-top: 60px;
    .slider-swiper-container {
      width: 100%;
      height: 340px;
      @media (max-width: 768px) {
        height: 220px;
      }
      .block-item {
        display: table;
        height: 290px;
        width: 360px;
        margin-bottom: 40px;
        @media (max-width: 768px) {
          height: inherit;
          margin-bottom: 20px;
        }
        .item {
          display: table-cell;
          vertical-align: middle;
          box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
          border-radius: 4px;
          background-color: #ffffff;
          height: 290px;
          text-align: center;
          padding: 15px;
          z-index: 1;
          position: relative;
          @media (max-width: 768px) {
            height: inherit;
            box-shadow: none;
          }
          h3 {
            color: #ec615f;
            font-family: "Gotham Bold";
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
            padding-bottom: 20px;
          }
          .texte {
            opacity: 0.9;
            color: #404143;
            font-family: "Gotham Book";
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;
          }
        }
      }
      .swiper-button {

      }
    }
  }
  .swiper-button {
    position: absolute;
    right: 60px;
    top: 87px;
    width: 130px;
    @media (max-width: 992px) {
      right: 0;
      left: 0;
      margin: auto;
      top: -90px;
    }
    @media (max-width: 768px) {
      display: none;
    }

    &:before {
      content: '';
      height: 37px;
      width: 1px;
      background-color: #e4e7f2;
      position: absolute;
      left: 0;
      right: 0;
      top: -27px;
      margin: auto;
    }
    .swiper-button-prev, .swiper-button-next {
      position: relative;
      background-size: contain;
      height: 23px;
      outline: none;
    }
    .swiper-button-prev {
      background-image: url('../img/arr2.svg');
      float: left;
      left: 0;
      &:hover {
        background-image: url('../img/arr1.svg');
        transform: rotate(180deg);
      }
    }
    .swiper-button-next {
      background-image: url('../img/arr2.svg');
      transform: rotate(180deg);
      float: right;
      left: 0;
      &:hover {
        background-image: url('../img/arr1.svg');
        transform: rotate(0deg);
      }
    }
  }
}

#partie-a-propos-3,
#section-6 {
  .galerie {
    margin: 40px 0 0 0;
    .item {
      display: table;
      position: relative;
      height: 60px;
      margin: 0 auto 30px auto;
      span {
        max-width: 100%;
        height: 60px;
        margin: auto;
        display: table-cell;
        vertical-align: middle;
        img {
          height: 100%;
          width: inherit;
        }
      }
    }
  }
}
