/*
|-----------------------
| 	     FOOTER
|-----------------------
|
*/

#footer {
  clear: both;
  .legals {
    opacity: 0.8;
    color: #404143;
    font-family: "Gotham Book";
    font-size: 14px;
    font-weight: 300;
    line-height: 25px;
    padding: 50px 0 40px 0;
    border-top: 2px solid #f9f9f9;
    background-color: #fff;
    @media (max-width: 992px) {
      padding: 20px 0;
    }

    a{
      opacity: 0.8;
      color: #404143;
      font-family: "Gotham Book";
      font-size: 14px;
      font-weight: 300;
      line-height: 25px;
      display: inline-block;
    }
    .ft-left {
      text-align: left;
      opacity: 0.6;
      color: #404143;
      font-family: "Gotham Book";
      font-size: 12px;
      font-weight: 300;
      line-height: 25px;
      a{
        color: #404143;
        font-family: "Gotham Book";
        font-size: 12px;
        font-weight: 300;
        line-height: 25px;
      }
      @media (max-width: 1200px) {
        text-align: center;
      }
    }
    .ft-center {
      width: 100%;
      text-align: center;
      li {
        display: inline-block;
        padding: 0px 10px;
        @media (max-width: 992px) {
          display: block;
        }
      }
    }
    .ft-right {
      text-align: right;
      opacity: 0.6;
      color: #404143;
      font-family: "Gotham Book";
      font-size: 12px;
      font-weight: 300;
      line-height: 25px;
      a{
        color: #404143;
        font-family: "Gotham Book";
        font-size: 12px;
        font-weight: 300;
        line-height: 25px;
      }
      @media (max-width: 1200px) {
        text-align: center;
      }
    }
  }
}