/*
|-----------------------
| 	    RESET CSS
|-----------------------
|
*/

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: inherit;
}

article, aside, details, figcaption, figure, footer, header, menu, nav, section {
  display: block;
}

button, input, textarea{
  &:focus{
    outline: none !important;
    box-shadow: none !important;
  }
}

img{
  max-width: 100%;
}

ul, ol, li{
  list-style: none;
  margin:0;
  padding: 0;
}

a{
  text-decoration: none !important;
  &:focus{
    outline: none !important;
  }
}